import { Meta, Title } from '@solidjs/meta';
import { Heading } from '@troon/ui';
import { For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { Content } from '../../../components/content';

export default function DEIPage() {
	return (
		<NoHydration>
			<Title>Senior Executives | Troon</Title>
			<Meta
				name="description"
				content="The senior executives and leadership team Troon and Troon's affiliated brands."
			/>

			<Content>
				<Heading as="h1">
					<span class="sr-only">Troon </span>Senior Executives
				</Heading>
				<ul class="grid gap-x-8 gap-y-2 sm:grid-cols-2 lg:grid-cols-3">
					<For each={executives}>
						{(person) => (
							<li class="flex flex-col">
								<span class="font-semibold">{person.name}</span>
								<span class="text-sm">{person.title}</span>
							</li>
						)}
					</For>
				</ul>

				<Heading as="h2">Other officers</Heading>
				<ul class="grid gap-x-8 gap-y-2 sm:grid-cols-2 lg:grid-cols-3">
					<For each={officers}>
						{(person) => (
							<li class="flex flex-col">
								<span class="font-semibold">{person.name}</span>
								<span class="text-sm">{person.title}</span>
							</li>
						)}
					</For>
				</ul>
			</Content>
		</NoHydration>
	);
}

const executives: Array<{ name: string; title: string }> = [
	{ name: 'Tim Schantz', title: 'President, Chief Executive Officer' },
	{ name: 'Ruth Engle', title: 'Chief Financial Officer' },
	{ name: 'Bruce Glasco', title: 'Chief Operating Officer' },
	{ name: 'Jay McGrath', title: 'Chief Legal Officer' },
	{ name: 'Mike Ryan', title: 'Chief Operating Officer' },
	{ name: 'Scott Van Newkirk', title: 'Chief Development Officer' },
	{ name: 'Rob DeMore', title: 'Executive Vice President, Troon Privé' },
	{ name: 'Ed Doyle', title: 'President, RealFood' },
	{ name: 'Alex Elmore', title: 'Executive Vice President, Owned-Leased Asset Group Division' },
	{ name: 'DJ Flanders', title: 'Executive Vice President, Troon International' },
	{ name: 'Phil Green', title: 'Executive Vice President, Managed Properties West' },
	{ name: 'Gene Hallman', title: 'President, Eventive Sports' },
	{ name: 'Jeff Hansen', title: 'Executive Vice President, General Counsel' },
	{ name: 'Matt Hurley', title: 'Executive Vice President, Managed Properties East' },
	{ name: 'Jeff Ma', title: 'Chief Digital Officer' },
	{ name: 'Jim McLaughlin', title: 'President, True Club Solutions' },
	{ name: 'Dave Nicholls', title: 'Executive Vice President, Science & Agronomy' },
	{ name: 'Chris Nielsen', title: 'Chief Information Officer' },
	{ name: 'C.A. Roberts, III', title: 'Mergers & Acquisitions, Business Development' },
	{ name: 'Allison Sheaves', title: 'Executive Vice President of Human Resources' },
	{ name: 'Megan Stallone', title: 'Executive Vice President, Controller' },
	{ name: 'Kris Strauss', title: 'Executive Vice President, Global Sales & Marketing' },
	{ name: 'Cindy Anderson', title: 'Senior Vice President, Clubhouse Design & Development' },
	{ name: 'Jason Barone', title: 'Senior Vice President, Financial Planning & Analysis' },
	{ name: 'Ricardo Catarino', title: 'Senior Vice President, Operations' },
	{ name: 'Ron Despain', title: 'Senior Vice President, Golf Course Development' },
	{ name: 'Tom Enders', title: 'Senior Vice President, Business Development' },
	{ name: 'Charlene Gallob', title: 'Senior Vice President, Procurement' },
	{ name: 'Mitch Harrell', title: 'Senior Vice President, Operations' },
	{ name: 'Anne Kaufman', title: 'Senior Vice President, Associate General Counsel' },
	{ name: 'Phillip Martin', title: 'Senior Vice President, Operations' },
	{ name: 'Matt McIntee', title: 'Senior Vice President, Business Development' },
	{ name: 'Darrell Morgan', title: 'Senior Vice President, Operations' },
	{ name: "Bill O'Brien", title: 'Senior Vice President, Operations' },
	{ name: 'Jeff Spangler', title: 'Senior Vice President, Science & Agronomy' },
	{ name: 'Gary Spivey', title: 'Senior Vice President, Finance and Managed Properties' },
];

const officers: Array<{ name: string; title: string }> = [
	{ name: 'Archie Cart', title: 'Vice President, Operations' },
	{ name: 'Carl Bielstein', title: 'Vice President, Operations' },
	{ name: 'Dan Zimmer', title: 'Vice President, Operations' },
	{ name: 'Guy Sugden', title: 'Vice President, Operations' },
	{ name: 'Jeff Thomasson', title: 'Vice President, Operations' },
	{ name: 'Joe Langford', title: 'Vice President, Operations' },
	{ name: 'Justin Herr', title: 'Vice President, Operations' },
	{ name: 'Justin Smith', title: 'Vice President, Operations' },
	{ name: 'Matt Molloy', title: 'Vice President, Operations' },
	{ name: 'Mike Conner', title: 'Vice President, Operations' },
	{ name: 'Nick Bednar', title: 'Vice President, Operations' },
	{ name: 'Ryan Whitney', title: 'Vice President, Operations' },
	{ name: 'Tony Marino', title: 'Vice President, Operations' },
	{ name: 'Jennifer Martin', title: 'Vice President, Human Resources' },
	{ name: 'Megan Clay', title: 'Vice President, Customer Finance' },
	{ name: 'Rob Manning', title: 'Vice President, Global Program Office' },
	{ name: 'Brian Hampson', title: 'Vice President, Science & Agronomy' },
	{ name: 'Simon Doyle', title: 'Vice President, Agronomy' },
	{ name: 'Jeff Schwinkendorf', title: 'Vice President – Casa Verde Golf' },
	{ name: 'Jim DeReuil', title: 'Vice President, Science & Agronomy' },
	{ name: 'Jeff Cathey', title: 'Vice President, Science & Agronomy' },
	{ name: 'Kristen Goulet', title: 'Vice President, Retail' },
	{ name: 'Dolf May', title: 'Vice President, Sales & Marketing' },
	{ name: 'Julia Kelly', title: 'Vice President, Membership Marketing' },
	{ name: 'Brandon Fowler', title: 'Vice President, Business Development' },
	{ name: 'Don Shirey', title: 'Vice President, Business Development' },
	{ name: 'Greg Leisher', title: 'Vice President, Business Development' },
	{ name: 'Joe Goodrich', title: 'Vice President, Business Development' },
	{ name: 'John Bartilomo', title: 'Vice President, Food & Beverage Operations' },
];
